import {
  INSTRUCTIONS,
  INSTRUCTIONS_LENGTH,
} from 'easyjs/lib/Interpreter/Instructions';

import { getXAPIEnv, getXUserKey } from '@/plugins/account';
import { CatalogConverter } from 'easyjs/lib/Interpreter/CatalogConverter';
import { doc as elasticDoc } from '@/utils/elasticsearch';

import Response from 'easyjs/lib/Http/Response';
import store from '@/store';
import Api from './api';

const getInstructionVerb = (item) => {
  if (item.delete && item.new) {
    return null;
  }
  if (item.delete && item._id) {
    return 'delete';
  }
  if (!item.delete && item.new) {
    return 'create';
  }
  if (!item.delete && !item.new) {
    return 'update';
  }
  return null;
};

// Corrigir o parent_id dos filhos
const correctParentIds = (targetItem) => {
  const arrayProps = ['items', 'edges', 'doughs', 'extra_ingredients'];

  CatalogConverter.recursiveCall(targetItem, (item) => {
    arrayProps.forEach((key) => {
      if (item[key] && item[key].length > 0) {
        item[key].forEach((child) => {
          child.parent = item._id || null;
        });
      }
    });
  });

  return targetItem;
};

// Corrigir o position dos filhos
const correctPositions = (targetItem) => {
  const arrayProps = ['items', 'edges', 'doughs', 'extra_ingredients'];

  CatalogConverter.recursiveCall(targetItem, (item) => {
    arrayProps.forEach((key) => {
      if (item[key] && item[key].length > 0) {
        item[key].forEach((child, childIndex) => {
          child.position = childIndex;
        });
      }
    });
  });

  return targetItem;
};

// Corrigir o parent_id dos filhos
const clearChilds = (targetItem) => {
  const arrayProps = ['items', 'edges', 'doughs', 'extra_ingredients'];

  arrayProps.forEach((key) => {
    if (
      targetItem[key] &&
      Array.isArray(targetItem[key]) &&
      targetItem[key].length > 0
    ) {
      targetItem[key] = [];
    }
  });

  return targetItem;
};

const checkIfHasChanges = (source, target) => {
  // Avaliar as propriedades de ambos os componentes
  const clonedTargerObject = clearChilds(cloneWithPosition(target));
  const targetKeys = Object.keys(clonedTargerObject).filter(
    (key) =>
      [
        '_id',
        'availability',
        'sizes',
        'items',
        'edges',
        'extra_ingredients',
        'doughs',
      ].indexOf(key) == -1
  );

  const clonedSourceObject = clearChilds(cloneWithPosition(source));
  const sourceKeys = Object.keys(clonedSourceObject).filter(
    (key) =>
      [
        '_id',
        'availability',
        'sizes',
        'items',
        'edges',
        'extra_ingredients',
        'doughs',
      ].indexOf(key) == -1
  );

  for (const sourceProp of sourceKeys) {
    // Se o target não possuir a propriedade de source ou então seus valores são diferentes retornar que existem diferenças
    if (
      !clonedTargerObject.hasOwnProperty(sourceProp) ||
      clonedSourceObject[sourceProp] != clonedTargerObject[sourceProp]
    ) {
      return true;
    }
  }

  for (const targetProp of targetKeys) {
    // Se o source não possuir a propriedade de target ou então seus valores são diferentes retornar que existem diferenças
    if (
      !clonedSourceObject.hasOwnProperty(targetProp) ||
      clonedSourceObject[targetProp] != clonedTargerObject[targetProp]
    ) {
      return true;
    }
  }

  // Checar a propriedade "availability" de ambos os componentes
  const sourceAvailability = source.availability || {
    daysOfTheWeek: 0,
    periods: [],
  };
  const targetAvailability = target.availability || {
    daysOfTheWeek: 0,
    periods: [],
  };

  if (sourceAvailability.daysOfTheWeek !== targetAvailability.daysOfTheWeek) {
    return true;
  }

  if (sourceAvailability.periods.length !== targetAvailability.periods.length) {
    return true;
  }

  for (let i = 0; i < sourceAvailability.periods.length; i++) {
    if (
      sourceAvailability.periods[i].beginTime !==
        targetAvailability.periods[i].beginTime ||
      sourceAvailability.periods[i].endTime !==
        targetAvailability.periods[i].endTime
    ) {
      return true;
    }
  }

  const sourceAvailabilityPeriods = sourceAvailability.periods;
  const targetAvailabilityPeriods = targetAvailability.periods;

  for (const sourcePeriod of sourceAvailabilityPeriods) {
    // Se para um determinado periodo da origem não existir nos periodos de destino retornar que há alterações
    const hasSome = targetAvailabilityPeriods.some(
      (targetPeriod) =>
        targetPeriod.beginTime == sourcePeriod.beginTime &&
        targetPeriod.endTime == sourcePeriod.endTime
    );

    if (!hasSome) {
      return false;
    }
  }

  for (const targetPeriod of targetAvailabilityPeriods) {
    // Se para um determinado periodo do destino não existir nos periodos da origem retornar que há alterações
    const hasSome = sourceAvailabilityPeriods.some(
      (sourcePeriod) =>
        targetPeriod.beginTime == sourcePeriod.beginTime &&
        targetPeriod.endTime == sourcePeriod.endTime
    );

    if (!hasSome) {
      return false;
    }
  }

  // Se ambos os tamanhos são identicos quer dizer que não diferencas entre os componentes
  // Aqui entra a verificação se ambos as propriedades são nulas ou indefinidas (null == null ou undefined == undefined)
  if (clonedTargerObject.sizes == clonedSourceObject.sizes) {
    return false;
  }

  // Se um dos objetos possuir algum valor que não seja uma array retornar que existe uma diferença entre os dois objetos
  if (
    !Array.isArray(clonedTargerObject.sizes) ||
    !Array.isArray(clonedSourceObject.sizes)
  ) {
    return true;
  }

  // Se a quantidade de itens dentro do tamanho em ambos os objetos são diferentes retornar que há diferenças
  if (clonedTargerObject.sizes.length != clonedSourceObject.sizes.length) {
    return true;
  }

  // Encontrar as tuplas de cada tamanho
  const fromToSizes = {};

  for (const sourceSize of clonedSourceObject.sizes) {
    // Para cada tamanho em source
    const key = sourceSize._id;

    fromToSizes[key] = [sourceSize]; // Criar propriedade com o _id do tamanho em fromToSizes

    // Buscar dentro dos tamanhos do target, um tamanho correspondente
    for (const targetSize of clonedTargerObject.sizes) {
      if (targetSize._id == sourceSize._id) {
        fromToSizes[key].push(targetSize);
      }
    }

    // Se não houver um tamanho correspondente retorne que existe uma diferença
    if (fromToSizes[key].length != 2) {
      return true;
    }
  }

  for (const targetSize of clonedTargerObject.sizes) {
    // Para cada tamanho em target
    // Verificar se existe a propriedade em fromToSizes com o seu _id retornar que existe diferenças entre ambos os itens
    if (!fromToSizes.hasOwnProperty(targetSize._id)) {
      return true;
    }
  }

  // Caso todos os tamanhos casem, fazer a validação direta entre as tuplas
  for (const fromTo of Object.values(fromToSizes)) {
    const [sourceSize, targetSize] = fromTo;
    if (checkIfHasChanges(sourceSize, targetSize)) {
      return true;
    }
  }

  return false;
};

const cloneWithPosition = (targetItem) => {
  const clonedObject = JSON.parse(JSON.stringify(targetItem));
  if (targetItem.hasOwnProperty('position')) {
    clonedObject.position = targetItem.position || 0;
  }
  return clonedObject;
};

const clearDeletedSizes = (targetItem) => {
  CatalogConverter.recursiveCall(targetItem, (item) => {
    if (item.sizes) {
      for (const sizeId in item.sizes) {
        const size = item.sizes[sizeId];
        if (size.delete) {
          item.sizes.splice(sizeId, 1);
        }
      }
    }
  });

  return targetItem;
};

export default class {
  static async catalogCatalog(showLoading = true, active) {
    if (active) {
      try {
        const elasticResponse = await elasticDoc('catalogs', getXAPIEnv());
        return new Response(elasticResponse.data._source.catalog);
      } catch (err) {
        showLoading && store.commit('store/setLoading', false);
        return new Response(false);
      }
    }
  }

  static async saveCatalog(targetItem, sourceCatalog, showLoading = true) {
    const instructions = [];
    const contextIds = {};

    // Corrigir o cardapio origem com o posicionamento correto
    correctPositions(sourceCatalog);

    // Corrigir o cardapio com o _id dos pais e a posição correta
    const schema = correctPositions(
      correctParentIds(
        clearDeletedSizes(JSON.parse(JSON.stringify(targetItem)))
      )
    );

    CatalogConverter.recursiveCall(schema, (item) => {
      if (item.hasOwnProperty('changed')) {
        delete item.changed;
      }
    });

    CatalogConverter.recursiveCall(schema, (item) => {
      if (item.type == 'catalog') {
        return;
      }

      const instruction = getInstructionVerb(item);
      // Se a instrução não for reconhecida, ignorar o nó
      if (!instruction) {
        return;
      }

      switch (instruction) {
        case 'delete':
          // Eliminar os itens abaixo desse nó para evitar as chamadas recursivas subsequentes
          clearChilds(item);

          // Adicionar instrução de exclusão do nó
          instructions.push([
            (BigInt(item._id) << INSTRUCTIONS_LENGTH) | INSTRUCTIONS.DELETE,
          ]);
          break;

        // Chaves adicionadas para criar um contexto local na criação das variaveis const
        case 'create': {
          // Criar o id no contexto de ids
          contextIds[item._id] = Object.keys(contextIds).length + 1;
          item._id = contextIds[item._id];
          // Se o id do pai do componente for temporário, buscar na tabela de contextos id em questão
          if (`${item.parent}`.indexOf('_') == 0) {
            item.parent = contextIds[item.parent];
          }

          const clonedObject = clearChilds(cloneWithPosition(item));
          instructions.push([
            (BigInt(item.parent || 0) << INSTRUCTIONS_LENGTH) |
              INSTRUCTIONS.CREATE,
            clonedObject,
          ]);
          break;
        }

        // Chaves adicionadas para criar um contexto local na criação das variaveis const
        case 'update': {
          const clonedObject = clearChilds(cloneWithPosition(item));

          // Buscar no cardápio o item correspondente ao atual
          const sourceItem = sourceCatalog.getItemsByParam(
            '_id',
            clonedObject._id
          );

          // Se não encontrou o componente no cardápio, significa que é uma criação de componente
          if (!sourceItem || !sourceItem[0]) {
            // Criar o id no contexto de ids
            contextIds[item._id] = Object.keys(contextIds).length + 1;
            item._id = contextIds[item._id];

            // Se o id do pai do componente for temporário, buscar na tabela de contextos id em questão
            if (`${item.parent}`.indexOf('_') == 0) {
              item.parent = contextIds[item.parent];
            }

            instructions.push([
              (BigInt(clonedObject.parent || 0) << INSTRUCTIONS_LENGTH) |
                INSTRUCTIONS.CREATE,
              clonedObject,
            ]);
            break;
          }

          // Checar se realmente houve alteração entre o componente checado e o que está no cardapio;
          if (checkIfHasChanges(sourceItem[0], clonedObject)) {
            instructions.push([
              (BigInt(item._id) << INSTRUCTIONS_LENGTH) | INSTRUCTIONS.UPDATE,
              clonedObject,
            ]);
          }

          break;
        }
      }
    });

    const plainedJson = JSON.stringify(
      { commands: instructions },
      (key, value) => (typeof value === 'bigint' ? value.toString() : value)
    );

    return Api(
      'catalog',
      'commands',
      'post',
      plainedJson,
      {
        'x-api-env': getXAPIEnv(),
        'x-user-key': getXUserKey(),
        'Content-Type': 'application/json',
      },
      showLoading
    );
  }

  static catalogUpdate(xApiEnv) {
    return Api('catalog', 'update', 'get', null, { 'x-api-env': xApiEnv });
  }
}
