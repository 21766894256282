import Api from './api';
import { getXUserKey } from '@/plugins/account';

export class EnvironmentsAPI {
  static async request(params) {
    return await Api(
      'environments',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      params.headers || {},
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async requestAuthenticated(params) {
    const accessToken = await getXUserKey();

    return await Api(
      'environments',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async me() {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: 'me',
      method: 'get',
      loading: false,
      noVersion: true,
    });
  }

  static async me() {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: 'me',
      loading: false,
      noVersion: true,
    });
  }

  static async getConfigurations(loading = true) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: 'configurations',
      loading: loading,
      noVersion: true,
    });
  }

  static async putConfigurations(data, loading = true) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: 'configurations',
      method: 'put',
      data,
      loading: loading,
      noVersion: true,
    });
  }

  static async getUnits(loading = true) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: 'units',
      loading,
      noVersion: true,
    });
  }

  static async getAvailableSources(loading = true) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: 'sources',
      loading,
      noVersion: true,
    });
  }

  static async postUnits(data) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: 'units',
      method: 'post',
      data,
      loading: true,
      noVersion: true,
    });
  }

  static async putUnit({ id, name, address }) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: `units/${id}`,
      method: 'put',
      data: { name, address },
      loading: true,
      noVersion: true,
    });
  }

  static async deleteUnit(unitId) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: `units/${unitId}`,
      method: 'delete',
      loading: true,
      noVersion: true,
    });
  }

  static async getSources(unitId) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: `units/${unitId}/sources`,
      loading: false,
      noVersion: true,
    });
  }

  static async addSource(unitId, origin, loading = true) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: `units/${unitId}/sources`,
      method: 'post',
      data: origin,
      loading: loading,
      noVersion: true,
    });
  }

  static async setSourceStatus(unitId, sourceId, status, loading = true) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: `units/${unitId}/sources/${sourceId}/status`,
      method: 'put',
      data: { status },
      loading: loading,
      noVersion: true,
    });
  }

  static async deleteSource(unitId, sourceId) {
    return await EnvironmentsAPI.requestAuthenticated({
      endpoint: `units/${unitId}/sources/${sourceId}`,
      method: 'delete',
      loading: true,
      noVersion: true,
    });
  }
}
