import { Response } from '@konv/http';
import Api from './api';
import { getXUserKey, getXAPIEnv } from '@/plugins/account';

export class AudienceAPI {
  static async request(params) {
    return await Api(
      'environments/audiences',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      params.headers || {},
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async requestAuthenticated(params) {
    const accessToken = await getXUserKey();

    return await Api(
      'environments/audiences',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async getAudiences(page = 0, limit = 18) {
    return await AudienceAPI.requestAuthenticated({
      data: { page, limit },
      noVersion: true,
      loading: false,
    });
  }

  static async getAudienceById(audienceId) {
    return await AudienceAPI.requestAuthenticated({
      endpoint: `${audienceId}`,
      noVersion: true,
    });
  }

  static async createAudience(audience, loading = true) {
    return await AudienceAPI.requestAuthenticated({
      method: 'post',
      data: audience,
      loading,
      noVersion: true,
    });
  }

  static async updateAudienceRules(audienceId, rules) {
    return await AudienceAPI.requestAuthenticated({
      endpoint: `${audienceId}/rules`,
      method: 'put',
      data: rules,
      noVersion: true,
    });
  }

  static async exportAudience(audienceId, exportType = 'json') {
    return await AudienceAPI.requestAuthenticated({
      endpoint: `${audienceId}/export/${exportType}`,
      method: 'get',
      noVersion: true,
      loading: false,
    });
  }

  static async updateAudience(id, audience) {
    const audienceResponse = await AudienceAPI.requestAuthenticated({
      endpoint: `${id}`,
      method: 'put',
      data: {
        name: audience.name,
      },
      noVersion: true,
    });

    return audienceResponse;
  }

  static async deletePublic(id) {
    return await AudienceAPI.requestAuthenticated({
      endpoint: `${id}`,
      method: 'delete',
      noVersion: true,
      loading: true,
    });
  }

  static async exportAudience(audienceId, exportType = 'json') {
    return await AudienceAPI.requestAuthenticated({
      endpoint: `${audienceId}/export/${exportType}`,
      method: 'get',
      noVersion: true,
      loading: false,
    });
  }
}
