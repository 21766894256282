import { getXAPIEnv, getXUserKey } from '@/plugins/account';
import Api from './api';

export default {
  getConfiguration(platform) {
    return Api(
      'order',
      `delivery/${platform}/configuration`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },

  getConfigurationStatus(platform) {
    return Api(
      'order',
      `delivery/${platform}/configuration/status`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv() }
    );
  },

  putConfiguration(platform, platformConfiguration) {
    return Api(
      'order',
      `delivery/${platform}/configuration`,
      'put',
      platformConfiguration,
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },
  getDeliveryStatusHistory(platform, orderId, deliveryPlatormShippingId) {
    return Api(
      'order',
      `delivery/${platform}/status/history/${orderId}/shipping/${deliveryPlatormShippingId}`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },
  getDeliveryStatus(platform, orderId) {
    return Api(
      'order',
      `delivery/${platform}/${orderId}/status`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },
  postDelivery(platform, orderId) {
    return Api(
      'order',
      `delivery/${platform}/${orderId}`,
      'post',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },
  putDeliveryStatus(platform, orderId, deliveryStatus) {
    return Api(
      'order',
      `delivery/${platform}/${orderId}/${deliveryStatus}`,
      'put',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },
};
