import Api from './api'
import { getXAPIEnv, getXUserKey } from '@/plugins/account'
import elasticsearch from '@/utils/elasticsearch'

const axios = require('axios')

export default {
    cashDesk (filter) {
      return Api('store', `reports/cashdesk`, 'post', filter, { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() })
    },
    reports (filter) {
      
      return elasticsearch(filter, getXAPIEnv())
      // return axios.post('http://Infra-EsSer-QOAKB14VT6K1-1009582255.us-east-2.elb.amazonaws.com/search', filter, { headers: { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }})
    }
  }
