import Api from './api'
import { getXUserKey, getXAPIEnv } from '@/plugins/account'

export const service = 'scheduler'

export default {

  create: function (data) {
    return Api('scheduler', '', 'post', { ...data, headers: { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() } }, { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() }, false)
  },

  getAllByType: function (scheduleType) {
    return Api('scheduler', `timers/${scheduleType}`, 'get', {}, { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() }, false)
  },

  getById: function (scheduleType, scheduleId) {
    return Api('scheduler', `timers/${scheduleType}/${scheduleId}`, 'get', {}, { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() }, false)
  },

  unschedule: function (scheduleType, scheduleId) {
    return Api('scheduler', `unschedule/${scheduleType}/${scheduleId}`, 'delete', {}, { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() }, false)
  },

}
