import Api from './api';
import { getXUserKey, getXAPIEnv } from '@/plugins/account';

export const service = 'address';

export default {
  /* --------------------------------- */
  /* -------------PAINEL-------------- */
  /* --------------------------------- */
  SupportLogin(oLogin) {
    return Api(
      'monitor',
      'user/login',
      'post',
      oLogin,
      { 'x-api-no-env': 1 },
      false
    );
  },

  /* Efetua login */
  Login(oLogin) {
    return Api('store', 'login', 'post', oLogin, { 'x-api-no-env': 1 }, false);
  },

  /* Busca dados do usuário logado */
  me(showLoading = true) {
    return Api(
      'store',
      'me',
      'get',
      { login_hash: getXUserKey() },
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() },
      showLoading
    );
  },

  /* Atualiza dados do usuário logado */
  updateMe(oAccount) {
    return Api('account', `${oAccount.id}`, 'put', oAccount, {
      'x-user-key': getXUserKey(),
    });
  },

  /* --------------------------------- */
  /* -------------PAINEL-------------- */
  /* --------------------------------- */

  /* Cria cliente */
  CreateCustomer(oCustomer) {
    return Api('account', 'create', 'post', oCustomer, {
      'x-user-key': getXUserKey(),
    });
  },

  /* Cria endereço */
  CreateAddress(oAddress) {
    return Api('address', 'create', 'post', oAddress, {
      'x-user-key': getXUserKey(),
    });
  },
  addUserAddress(userId, address, position) {
    return Api('address', `address/${userId}`, 'post', address, {
      'x-api-env': getXAPIEnv(),
      'x-user-key': getXUserKey(),
    });
  },

  deleteUserAddress(addressId, userId) {
    return Api(
      'address',
      `address/${userId}/${addressId}`,
      'delete',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },

  zipcode(zipcode) {
    Api(
      'address',
      `zipcode/${zipcode}`,
      'get',
      {},
      { 'x-user-key': getXUserKey() }
    );
  },

  stringAddress(query) {
    return null; 
  },

  address(addressData) {
    return null;
  },

  /* Lista todos os estados, cidades e bairros do estabelecimento */
  ListAddressInfo() {
    return Api('address', 'list', 'get', {}, { 'x-user-key': getXUserKey() });
  },

  /* Lista extrato de pontos do cliente */
  ListLoyaltyReport(oPageConfig) {
    return Api('account', 'loyalty-report', 'get', oPageConfig, {
      'x-user-key': getXUserKey(),
    });
  },

  /* Login via Facebook */
  FbLogin(oLogin) {
    return Api('account', 'loginfb', 'post', oLogin, {
      'x-user-key': getXUserKey(),
    });
  },

  /* Envia email para recuperar senha */
  SendEmailPasswordForgotten(oAccount) {
    return Api('account', 'forgot-password', 'post', oAccount, {
      'x-user-key': getXUserKey(),
    });
  },

  /* Envia nova senha */
  SendPasswordPasswordForgotten(oAccount) {
    return Api('account', 'retrieve-password', 'post', oAccount, {
      'x-user-key': getXUserKey(),
    });
  },

  CreateAddresses(oAddress) {
    return Api(
      'address',
      'create',
      'post',
      { addresses: [oAddress] },
      { 'x-user-key': getXUserKey() }
    );
  },

  ListAddresses(origin) {
    return Api(
      'address',
      'address-list',
      'get',
      { origin },
      { 'x-user-key': getXUserKey() }
    );
  },

  UpdateAddresses(oAddress) {
    return Api('address', 'update', 'put', oAddress, {
      'x-user-key': getXUserKey(),
    });
  },

  RemoveAddresses(iAddressId) {
    return Api(
      'address',
      'delete',
      'delete',
      { address_id: iAddressId },
      { 'x-user-key': getXUserKey() }
    );
  },

  VerifyEmail(email) {
    return Api('account', 'email-validate', 'post', email);
  },

  requestEmailCode(email, showLoading = true) {
    return Api('store', `verify/email/${email}`, 'get', null, {}, showLoading);
  },

  validateEmailCode(code, email, showLoading = true) {
    return Api(
      'store',
      `verify/email/${email}/${code}`,
      'get',
      null,
      {},
      showLoading
    );
  },

  validateAccessKeyCode(accesskey, showLoading = true) {
    return Api(
      'store',
      `verify/access-key/${accesskey}`,
      'get',
      null,
      {},
      showLoading
    );
  },

  requestPhoneCode(phone, showLoading = true) {
    return Api('store', `verify/phone/${phone}`, 'get', null, {}, showLoading);
  },

  requestUsernameCode(username) {
    return Api('store', `verify/username/${username}`, 'get', null);
  },

  validatePhoneCode(code, phone, showLoading = true) {
    return Api(
      'store',
      `verify/phone/${phone}/${code}`,
      'get',
      null,
      {},
      showLoading
    );
  },

  validateUsernameCode(code, username) {
    return Api('store', `verify/username/${username}/${code}`, 'get', null);
  },

  isAccount(username, showLoading = true) {
    return Api(
      'store',
      `is_account/${username}`,
      'get',
      null,
      { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() },
      showLoading
    );
  },

  hasPasswordPermission(username) {
    return Api('store', `verify/password/permission/${username}`, 'get', null);
  },

  changePassword(username, password, token) {
    return Api('store', `user/password/${username}`, 'put', {
      password,
      token,
    });
  },

  retrieveUsername(email) {
    return Api('store', `user/username/retrieve/${email}`, 'get');
  },

  updatePassword(oPassword) {
    return Api('store', 'update-password', 'put', oPassword, {
      'x-user-key': getXUserKey(),
    });
  },
};
