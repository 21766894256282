/* eslint-disable */
import Qs from 'qs';
import store from '@/store';
import Response from '@/plugins/core/http/Response';
import { DelayFunction } from '../utils/delay';
import Axios from 'axios'

global.delayFunction = global.delayFunction || new DelayFunction();

const { delayFunction } = global;
delayFunction.fn = () => {
  store.commit('store/setLoading', false);
};

const url = window.location.search.substr(1).split('&');
/* eslint-disable */
for (const i in url) {
  if (url[i].split('=')[0] === 'client') {
    apiKey = url[i].split('=')[1];
  }

  if (url[i].split('=')[0] === 'schema') {
    localStorage.setItem('schema', url[i].split('=')[1]);
  }
}

Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json, text/plain, text/html';

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error;
  }
);

export default async (resource, method, httpMethod = 'get', data = {}, headers = {}, showLoading, timeLoadingClose = 1500, noVersion = false) => {
  let sUrl = `${process.env.VUE_APP_API}/${resource}${!noVersion ? `/${process.env.VUE_APP_API_VERSION}` : ''}` + (method ? `/${method}` : '');
  /* No caso de método get converte o onjeto para query string */
  if (httpMethod === 'get') {
    sUrl += '?' + Qs.stringify(data, { arrayFormat: 'repeat' });;
  }
  store.commit('store/setLoading', showLoading);
  /* eslint-disable */
  try {
    const response = await Axios.request({
      url: sUrl,
      crossdomain: true,
      method: httpMethod,
      data: data,
      headers: headers
    });

    if (showLoading) {
      delayFunction.addDelay();
    }
    
    if(response.data.response) {
      return new Response(
        response.data.response.data || null,
        response.data.response.code || 200,
        response.data.response.errors || []
      )  
    }

    return new Response(
      response.data.data || null,
      response.data.code || 200,
      response.data.errors || []
    );

  } catch (err) {
    console.log(err);
    setTimeout(() => store.commit('store/setLoading', false), timeLoadingClose);
  }
}
/* eslint-enable */
