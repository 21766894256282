import Api from './api';
import { getXUserKey } from '@/plugins/account';
import { Response } from "@konv/http";

export class TestShootingSmsBody {
    phone = null;
    message = null;
    replacements = {
        url: {
            type: null,
            value: null
        },
        beneficio: {
            type: null,
            value: null
        },
        valorrecebido: null
    }

    constructor(data) {
        this.phone = data.phone;
        this.message = data.message;
        if (data.replacements) {
            const { url, beneficio, valorrecebido } = data.replacements;
            this.replacements.url = url
            this.replacements.beneficio = beneficio
            this.replacements.valorrecebido = valorrecebido
        }
    }

    toJSON() {
        const { phone, message, replacements } = this
        const returnBody = {
            phone,
            message
        }

        if (replacements) {
            returnBody.replacements = replacements
        }

        return returnBody;
    }
}

export class TestShootingAPI {

    static async request(params) {
        return await Api(
            'environments/test-shooting',
            params.endpoint,
            params.method || 'get',
            params.data || {},
            params.headers || {},
            params.loading !== undefined ? params.loading : true,
            params.timeLoadingClose || 1500,
            params.noVersion !== undefined ? params.noVersion : false
        );
    }

    static async requestAuthenticated(params) {
        const accessToken = await getXUserKey();

        return await Api(
            'environments/test-shooting',
            params.endpoint,
            params.method || 'get',
            params.data || {},
            { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
            params.loading !== undefined ? params.loading : true,
            params.timeLoadingClose || 1500,
            params.noVersion !== undefined ? params.noVersion : false
        );
    }

    /**
     * 
     * @param {TestShootingSmsBody} testShootingSmsBody 
     * @returns {Response}
     */
    static async postSms(testShootingSmsBody) {

        return await TestShootingAPI.requestAuthenticated({
            endpoint: `/sms`,
            method: 'post',
            data: testShootingSmsBody,
            loading: false,
            noVersion: true
        })
    }
}