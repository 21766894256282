import { Status } from 'easyjs/lib/Schema/Order';
import { getXUserKey, getXAPIEnv } from '@/plugins/account';
import Api from './api';

export const service = 'order';

export default class OrderAPI {

  static async request(params) {
    return await Api(
      'order',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      params.headers || { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() },
      params.loading !== undefined ? params.loading : true
    )
  }

  /* Lista Pedidos por status */
  static async list(afterAt = null, showLoading = true, range = null, limit = 10, page = 1, term = '') {
    const oAfterAt = afterAt && { afterAt: afterAt.toISOString() };
    const oRange = range && { dateStart: range[0].toISOString(), dateEnd: range[1].toISOString() };

    const params = oRange || oAfterAt || {};
    const qlResponse = await OrderAPI.request({
      endpoint: 'orders',
      loading: showLoading,
      data: {
        ...params,
        term: term.toLowerCase(),
        sort: 'updated_at',
        direction: 'DESC',
        excludeFields: ['deliveryStrip', 'cashback'],
        limit: limit,
        page: page,
        status: [Status.IN_PRODUCTION, Status.ON_DELIVERY, Status.FINISHED]
      }
    });

    return qlResponse;
  }

  static async get(id, showLoading = true) {
    return await OrderAPI.request({
      endpoint: 'orders/' + id,
      loading: showLoading
    })
  }

  static async update(id, order) {
    return await OrderAPI.request({
      endpoint: 'orders/' + id,
      method: 'put',
      data: order,
      loading: false
    });
  }

  /* Lista novos pedidos */
  static async new() {
    return await OrderAPI.request({
      endpoint: 'orders/status/opened'
    })
  }

  /* Rejeita um pedido */
  static async reject(id) {
    return await OrderAPI.request({
      endpoint: `status/reject/${id}`,
      method: 'put',
      data: {},
      loading: false
    })
  }

  /* Cancela um pedido */
  static async cancel(id) {
    return await OrderAPI.request({
      endpoint: `status/cancel/${id}`,
      method: 'put',
      data: {},
      loading: false
    })
  }

  /* Aceita um pedido */
  static async accept(id) {
    return await OrderAPI.request({
      endpoint: `status/accept/${id}`,
      method: 'put',
      data: {},
      loading: false
    })
  }

  /* Entrega um pedido */
  static async deliver(id) {
    return await OrderAPI.request({
      endpoint: `status/delivery/${id}`,
      method: 'put',
      data: {},
      loading: false
    })
  }

  /* Finaliza um pedido */
  static async finish(id) {
    return await OrderAPI.request({
      endpoint: `status/finish/${id}`,
      method: 'put',
      data: {},
      loading: false
    })
  }

  static async checkout(pOrder) {
    return await OrderAPI.request({
      endpoint: `checkout`,
      method: 'post',
      data: pOrder,
      loading: false
    });
  }

  static async syncDelivery(platform, orderId) {
    return await OrderAPI.request({
      endpoint: `delivery/${platform}/sync/${orderId}`,
      method: 'put',
      data: {},
      loading: false
    });
  }
}
