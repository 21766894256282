import Api from './api';
import { getXUserKey } from '@/plugins/account';
import { Response } from '@konv/http'


export class EventAPI {
    static async request(params) {
        return await Api(
            'environments/events',
            params.endpoint,
            params.method || 'get',
            params.data || {},
            params.headers || {},
            params.loading !== undefined ? params.loading : true,
            params.timeLoadingClose || 1500,
            params.noVersion !== undefined ? params.noVersion : false
        );
    }

    static async requestAuthenticated(params) {
        const accessToken = await getXUserKey();

        return await Api(
            'environments/events',
            params.endpoint,
            params.method || 'get',
            params.data || {},
            { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
            params.loading !== undefined ? params.loading : true,
            params.timeLoadingClose || 1500,
            params.noVersion !== undefined ? params.noVersion : false
        );
    }

    static async getEvents({ unitId, sourceId, eventType }, page = 0, limit = 18) {
        return await EventAPI.requestAuthenticated({
            endpoint: ``,
            data: {
                eventType: eventType,
                unitId: unitId,
                sourceId: sourceId,
                page: page,
                limit: limit
            },
            loading: false,
            noVersion: true
        })
    }
}