import Api from './api';
import { getXUserKey } from '@/plugins/account';
import Response from '@/plugins/core/http/Response';

export class StandaloneCouponsAPI {
    static async requestAuthenticated(params) {
        const accessToken = await getXUserKey();
        return Api(
            `environments/standalone-coupons`,
            params.endpoint,
            params.method || 'get',
            params.data || {},
            { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
            params.loading !== undefined ? params.loading : true,
            params.timeLoadingClose || 1500,
            params.noVersion !== undefined ? params.noVersion : false
        );
    }

    static async getStandaloneCoupons(
        status = 'ACTIVATED',
        page = 0,
        limit = 12
    ) {
        return StandaloneCouponsAPI.requestAuthenticated({
            endpoint: '',
            method: 'get',
            noVersion: true,
            loading: true,
            data: { status, page, limit },
        });
    }

    static async getStandaloneCouponById(couponId) {
        return StandaloneCouponsAPI.requestAuthenticated({
            endpoint: `${couponId}`,
            method: 'get',
            noVersion: true,
            loading: true,
        });
    }

    static async postStandaloneCoupons(data) {
        return StandaloneCouponsAPI.requestAuthenticated({
            endpoint: '',
            method: 'post',
            data,
            noVersion: true,
        });
    }

    static async putStandaloneCouponStatus(couponId, status) {
        return StandaloneCouponsAPI.requestAuthenticated({
            endpoint: `${couponId}/status`,
            method: 'put',
            noVersion: true,
            loading: false,
            data: { status },
        });
    }
}
