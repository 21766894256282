import Api from '../api';
import { getXUserKey, getXAPIEnv } from '@/plugins/account';

export default {
  getModuleEnvironment() {
    return Api(
      'modules',
      `modules/environment`,
      'GET',
      {},
      { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() },
      false
    );
  },
};
