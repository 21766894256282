import Api from './api'

export default class AddressAPI {
    static async request(params) {
        return await Api(
          'addresses',
          params.endpoint,
          params.method || 'get',
          params.data || {},
          params.headers || {},
          params.loading !== undefined ? params.loading : true,
          params.timeLoadingClose || 1500,
          params.noVersion !== undefined ? params.noVersion : false
        );
      }

    static async autoCompleteAddress(query, sessionToken, loading = false) {
        return await AddressAPI.request({
            endpoint: 'search',
            loading: loading,
            noVersion: true,
            loading: false,
            data: {
                q: query,
                token: sessionToken
            }
        })
    }

    static async addressDetail(placeId, sessionToken, loading = false) {
        return await AddressAPI.request({
            endpoint: `places/${placeId}`,
            noVersion: true,
            loading: false,
            data: { token: sessionToken }
        })
    }

    static async addressDDD(shortState, city, loading = false) {
        // return await AddressAPI.request({
        //     endpoint: `city/ddd/${shortState}/${city}`,
        //     loading: loading
        // })
    }
}
