import Api from './api';
import { getXAPIEnv, getXUserKey } from '@/plugins/account';

export default {
  getJunoConfiguration() {
    return Api(
      'payment',
      'juno/configuration',
      'get',
      null,
      { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() },
      true
    );
  },
  putJunoConfiguration(data = {}) {
    return Api(
      'payment',
      'juno/configuration',
      'put',
      data,
      { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() },
      true
    );
  },
};
