import Api from './api';
import { getXUserKey, getXAPIEnv } from '@/plugins/account';

export class EnvironmentAPI {
  static async request(params) {
    return await Api(
      'environments',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      params.headers || {},
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async requestAuthenticated(params) {
    const accessToken = await getXUserKey();

    return await Api(
      'environments',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async requestCustomers(paginate = 0, filters) {
    const limit = 18;
    const page = paginate * limit;
    const query = Object.keys(filters || {}).reduce(
      (acc, key) => {
        if (filters[key]) {
          acc = { ...acc, [key]: filters[key] };
        }
        return acc;
      },
      { limit, offset: page }
    );
    const variaveis = Object.keys(query).map((key) => `${key}`);
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: 'customers',
      method: 'get',
      data: query,
      headers: variaveis,
      loading: false,
      noVersion: true,
    });
  }

  static async requestCustomersId(id) {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `customers/${id}`,
      method: 'get',
      loading: false,
      noVersion: true,
    });
  }

  static async getUnits() {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `units`,
      method: 'get',
      loading: false,
      noVersion: true,
    });
  }

  static async getSources(id) {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `units/${id}/sources`,
      method: 'get',
      loading: false,
      noVersion: true,
    });
  }

  static async getCampaigns(page = 1, limit = 18, filters) {
    const query = Object.keys(filters || {}).reduce(
      (acc, key) => {
        if (filters[key]) {
          acc = { ...acc, [key]: filters[key] };
        }
        return acc;
      },
      { limit, page }
    );
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `campaigns`,
      method: 'get',
      data: query,
      loading: false,
      noVersion: true,
    });
  }

  static async getCampaignsId(id) {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `campaigns/${id}`,
      method: 'get',
      loading: false,
      noVersion: true,
    });
  }

  static async updateCampaings(id, data) {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `campaigns/${id}`,
      method: 'put',
      data,
      loading: true,
      noVersion: true,
    });
  }

  static async getCampaignById(id) {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `campaigns/${id}`,
      method: 'get',
      loading: true,
      noVersion: true,
    });
  }

  static async getCampaignReportById(id) {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `campaigns/${id}/report`,
      method: 'get',
      loading: true,
      noVersion: true,
    });
  }

  static async postCampaigns(data) {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `campaigns`,
      method: 'post',
      data,
      loading: true,
      noVersion: true,
    });
  }

  static async putCampaignStatusById(id, status) {
    const payload = {
      status,
    };
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `campaigns/${id}`,
      method: 'put',
      data: payload,
      loading: true,
      noVersion: true,
    });
  }

  static async removeCampaign(id) {
    return await EnvironmentAPI.requestAuthenticated({
      endpoint: `campaigns/${id}`,
      method: 'delete',
      loading: true,
      noVersion: true,
    });
  }
}
