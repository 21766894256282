import Api from './api';
import { getXUserKey } from '@/plugins/account';
import Response from '@/plugins/core/http/Response';

export class CashbackProgramAPI {
    static async request(params) {
        return Api(
            'environments/cashback-programs',
            params.endpoint,
            params.method || 'get',
            params.data || {},
            params.headers || {},
            params.loading !== undefined ? params.loading : true,
            params.timeLoadingClose || 1500,
            params.noVersion !== undefined ? params.noVersion : false
        );
    }

    static async requestAuthenticated(params) {
        const accessToken = await getXUserKey();
        return Api(
            'environments/cashback-programs',
            params.endpoint,
            params.method || 'get',
            params.data || {},
            { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
            params.loading !== undefined ? params.loading : true,
            params.timeLoadingClose || 1500,
            params.noVersion !== undefined ? params.noVersion : false
        );
    }

    static async getProgram(id = null) {
        return await CashbackProgramAPI.requestAuthenticated({
            endpoint: id || '',
            method: 'get',
            noVersion: true,
            loading: true,
        });
    }

    static async getProgramReports(id) {
        return CashbackProgramAPI.requestAuthenticated({
            endpoint: `${id}/reports`,
            method: 'get',
            noVersion: true,
            loading: true,
        });
    }

    static async getProgramHistory(page, limit) {
        return CashbackProgramAPI.requestAuthenticated({
            endpoint: 'history',
            method: 'get',
            noVersion: true,
            loading: false,
            data: { page, limit }
        });
    }

    static async putProgram(programData) {
        return CashbackProgramAPI.requestAuthenticated({
            endpoint: '',
            method: 'put',
            noVersion: true,
            data: programData,
            loading: false
        });
    }


    static async putStatus(id, status) {
        return CashbackProgramAPI.requestAuthenticated({
            endpoint: `${id}/status/${status}`,
            method: 'put',
            noVersion: true,
            loading: true,
        });
    }
}
