import Api from './api';
import { getXUserKey } from '@/plugins/account';

export class CampaignAPI {
  static async request(params) {
    return Api(
      'environments/campaigns',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      params.headers || {},
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async requestAuthenticated(params) {
    const accessToken = await getXUserKey();

    return Api(
      'environments/campaigns',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async getCampaignCreatives(campaignId, page = 1, limit = 12, filters) {
    const query = { limit, page };
    if (filters.name) query.name = filters.name;

    return CampaignAPI.requestAuthenticated({
      endpoint: `${campaignId}/creatives`,
      method: 'get',
      data: query,
      noVersion: true,
      loading: false,
    });
  }

  static async getCampaignCreativeById(campaignId, creativeId) {
    return CampaignAPI.requestAuthenticated({
      endpoint: `${campaignId}/creatives/${creativeId}`,
      noVersion: true,
    });
  }

  static async createCreative(campaignId, creative) {
    return CampaignAPI.requestAuthenticated({
      endpoint: `${campaignId}/creatives`,
      method: 'post',
      data: creative,
      noVersion: true,
    });
  }

  static async deleteCreative(campaignId, creativeId) {
    return CampaignAPI.requestAuthenticated({
      endpoint: `${campaignId}/creatives/${creativeId}`,
      method: 'delete',
      noVersion: true,
      loading: true,
    });
  }

  static async updateCampaignCreative(campaignId, creativeId, creative) {
    return CampaignAPI.requestAuthenticated({
      endpoint: `${campaignId}/creatives/${creativeId}`,
      method: 'put',
      data: creative,
      noVersion: true,
    });
  }
}
