import { getXAPIEnv, getXUserKey } from '@/plugins/account';
import Api from './api';

export default {
  storeInfo() {
    return Api('store', 'info', 'get', {}, {});
  },
  storeDeliveryAreas() {
    return Api('store', 'delivery-areas', 'get', {}, {});
  },
  storeIsOpen(timestamp = null) {
    return Api(
      'store',
      'is_open',
      'get',
      timestamp ? { date: timestamp } : {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() },
      false
    );
  },
  AddressList() {
    return Api('address', 'list', 'get', {}, {});
  },

  storeGetConfiguration(configurationAlias = null, loading = false) {
    return Api(
      'store',
      `configuration${configurationAlias ? `/${configurationAlias}` : ''}`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() },
      loading
    );
  },

  storeConfiguration(data, loading = true) {
    return Api(
      'store',
      'configuration',
      'put',
      data,
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() },
      loading
    );
  },

  cashDesk(filter) {
    return Api(
      'store',
      `billing/cashdesk/${filter}`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },

  isEnvironment(environmentAlias) {
    return Api('store', `is_environment/${environmentAlias}`, 'get');
  },

  catalogImporter(data) {
    return Api('store', 'crawler/importerCatalog', 'post', data, {
      'x-api-env': getXAPIEnv(),
      'x-user-key': getXUserKey(),
    });
  },

  verifyWhetherCronJobHasFinished(cronId) {
    return Api(
      'store',
      `cron/status/${cronId}`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },

  addLead(name, email, phone) {
    return Api('store', `lead`, 'post', { name, email, phone }, {});
  },

  delete() {
    return Api(
      'store',
      `environment`,
      'delete',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },

  getDomains() {
    return Api(
      'store',
      `domains`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() }
    );
  },
};
