import Api from '../api';
import { getXUserKey, getXAPIEnv } from '@/plugins/account';

export default {
  getCampaignDetails(id) {
    return Api(
      'performance',
      `campaign/listOne/${id}`,
      'GET',
      {},
      { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() },
      false
    );
  },
  createCampaign(data) {
    return Api(
      'performance',
      `campaign/create`,
      'POST',
      data,
      { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() },
      false
    );
  },
  updateCampaign(data, id) {
    return Api(
      'performance',
      `campaign/update/${id}`,
      'PUT',
      data,
      { 'x-user-key': getXUserKey(), 'x-api-env': getXAPIEnv() },
      false
    );
  },

  getCapaignByStatus(status) {
    return Api(
      'performance',
      `campaign?status=${status}`,
      'get',
      {},
      { 'x-api-env': getXAPIEnv(), 'x-user-key': getXUserKey() },
      true
    );
  },
  getCampaigns({ status, search, type }, offset) {
    return Api(
      'performance',
      `campaign`,
      'get',
      { status, search, type },
      {
        'x-api-env': getXAPIEnv(),
        'x-user-key': getXUserKey(),
        'x-pagination-limit': 10,
        'x-pagination-offset': offset,
      },
      false
    );
  },
};
