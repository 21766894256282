import { getXUserKey } from '@/plugins/account';
import Api from './api';

export class OAuthAPI {

  static async request(params) {
    return await Api(
      'oauth',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      params.headers || {},
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    )
  }

  static async requestAuthenticated(params) {
    const accessToken = await getXUserKey();
    return Api(
      'oauth',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async authenticate(oLogin) {
    return await OAuthAPI.request({
      endpoint: 'authenticate',
      method: 'post',
      data: oLogin,
      loading: false,
      noVersion: true
    })
  }

  static async refresh() {
    return await OAuthAPI.requestAuthenticated({
      endpoint: 'refresh-token',
      method: 'post',
      loading: false,
      noVersion: true
    })
  }
}