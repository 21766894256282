import Api from './api';
import { getXUserKey, getXAPIEnv } from '@/plugins/account';

export class UsersAPI {
  static async request(params) {
    return await Api(
      'users',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      params.headers || {},
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async requestAuthenticated(params) {
    const accessToken = await getXUserKey();

    return await Api(
      'users',
      params.endpoint,
      params.method || 'get',
      params.data || {},
      { Authorization: `Bearer ${accessToken}`, ...(params.headers || {}) },
      params.loading !== undefined ? params.loading : true,
      params.timeLoadingClose || 1500,
      params.noVersion !== undefined ? params.noVersion : false
    );
  }

  static async saveUsers(data) {
    return await UsersAPI.requestAuthenticated({
      endpoint: '',
      method: 'post',
      data,
      loading: true,
      noVersion: true,
    });
  }

  static async users() {
    return await UsersAPI.requestAuthenticated({
      endpoint: '',
      method: 'get',
      loading: false,
      noVersion: true,
    });
  }

  static async me() {
    return await UsersAPI.requestAuthenticated({
      endpoint: 'me',
      method: 'get',
      loading: true,
      noVersion: true,
    });
  }

  static async ChangePassword(passwordData) {
    return await UsersAPI.requestAuthenticated({
      endpoint: 'password',
      method: 'put',
      data: passwordData,
      noVersion: true,
    });
  }

  static async putMe(user) {
    return await UsersAPI.requestAuthenticated({
      endpoint: `me`,
      method: 'put',
      data: user,
      noVersion: true,
    });
  }

  static async putUser(userId, user) {
    return await UsersAPI.requestAuthenticated({
      endpoint: `${userId}`,
      method: 'put',
      data: user,
      noVersion: true,
    });
  }

  static async DispatchUser(id) {
    return await UsersAPI.requestAuthenticated({
      endpoint: `${id}`,
      method: 'delete',
      noVersion: true,
    });
  }
}
